
(function($) {

    $(window).on('load', loadLatestPost);
    //const wpjson = 'wp/v2/posts?per_page=1&amp;sticky=1';
    const wpjson = 'wp/v2/posts?sticky=true&per_page=1';
    function loadLatestPost(){
        if (document.body.classList.contains('home')) {
            const links = document.head.getElementsByTagName('link');
            const wpAPI = [].filter.call(links,el => el.rel === 'https://api.w.org/');
            const loadToast = (post) => {
                const $toast =  $('<div id="toast-top-right" class="toast absolute z-50 items-center w-full max-w-xl text-white" style="display: none;" role="alert" aria-live="assertive" aria-atomic="true">\n' +
                    '  <div class="toast-header flex w-full pb-1 px-4 sm:px-11 pt-6 shadow ">\n' +
                    '    <label class="block mr-auto uppercase text-xl font-light">Latest Article</label>\n' +
                    '    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">\n' +
                    '      <span aria-hidden="true">&times;</span>\n' +
                    '    </button>\n' +
                    '  </div>\n' +
                    '  <div class="toast-body block px-4 sm:px-11 pb-6 shadow ">\n' +
                    '    <a href="'+post.link+'" class="font-bold text-3xl leading-none no-underline">'+post.title.rendered+'</a>\n' +
                    '  </div>\n' +
                    '</div>');
                $toast.insertAfter( ".site-inner" ).delay( 3000 ).slideDown( "slow", function() {
                    console.log('Animation complete.');
                }).find('button').on('click', function(){
                    $toast.slideUp('slow')
                });
            }
            $.getJSON( wpAPI[0].href + wpjson)
                .done(function(json) {
                    if(json.length > 0) {
                        loadToast(json[0]);
                    }else{
                        // no stickies
                        $.getJSON( wpAPI[0].href + 'wp/v2/posts?per_page=1').done(function(json) {
                            if(json.length > 0) {
                                loadToast(json[0]);
                            }else{
                                console.log('No posts found to toast')
                            }
                        });
                    }
                })
                .fail(function() {
                    $.getJSON( wpAPI[0].href + 'wp/v2/posts?per_page=1').done(function(json) {
                        if(json.length > 0) {
                            loadToast(json[0]);
                        }else{
                            console.log('No posts found to toast')
                        }
                    });
                });
        }
    }

})( jQuery );