const debounce = require('lodash.debounce');
const throttle = require('lodash.throttle');
(function($) {
    const $Header = $('.site-header');
    const ele = document.querySelector(':root');
    const convertRemToPixels = function(rem){
        numrem = Number(rem.replace(/[^\d.]/g,''));
        return numrem * parseFloat(getComputedStyle(ele).fontSize);
    }
    const minHeight = convertRemToPixels('4rem');
    const logoWidth = convertRemToPixels(getComputedStyle(ele).getPropertyValue('--site-logo-width'));
    let fullHeight = convertRemToPixels(getComputedStyle(ele).getPropertyValue('--site-header-height'));

    let lastScrollY = 0;
    let thisScrollY = 0;
    let scrollDir = 'down';
    let navPosY = 0;
    let scrollSlack = (fullHeight - minHeight);
    let marker = scrollSlack;

    const checkNavfix = function(){
        let scrollAmt = lastScrollY / scrollSlack;
        if (scrollAmt > 1) {
            scrollAmt = 1;
            $Header.addClass('fixit');
            if(thisScrollY < lastScrollY){
                // we use marker to position the nav just above scroll-point
                if(scrollDir !== 'down'){
                    scrollDir = 'down';
                    marker = thisScrollY+navPosY;
                }
                navPosY = Math.min(lastScrollY-marker, minHeight)*-1;
            }else{
                if(scrollDir !== 'up'){
                    scrollDir = 'up';
                    marker = thisScrollY+navPosY;
                }
                navPosY = Math.min(marker-lastScrollY, 0);
            }
        } else {
            $Header.removeClass('fixit');
            navPosY = 0;
        }
        // todo: Introduce heightScale and CSS transform on header height to remove jankiness
        let newHeight = fullHeight - Math.max((scrollSlack * scrollAmt),0);
        let imgScale = Math.min( (newHeight*1.5)/logoWidth, 1);
        //let headerScale = newHeight / fullHeight;
        ele.style.setProperty('--site-header-height-scrolled', newHeight + 'px');
        //ele.style.setProperty('--site-header-scale', headerScale);
        //$Header.css({ transform: "translateY("+navPosY+"px) scaleY("+headerScale+")"})
        $Header.css({ transform: "translateY("+navPosY+"px)"})
            .find('img.svg-img').css({ transform: "scale("+imgScale+")"}) //.width(imgWidth).height(imgWidth * 62/160);
    };
    const updateHeaderSize = function (){
        fullHeight = convertRemToPixels(getComputedStyle(ele).getPropertyValue('--site-header-height'));
        scrollSlack = (fullHeight - minHeight);
        checkNavfix();
    }
    $(window).on('load resize', debounce(updateHeaderSize, 200));
    $(window).on('scroll', throttle(function() {
        thisScrollY = lastScrollY;
        lastScrollY = $(window).scrollTop();
        checkNavfix();
    }, 1, {leading:true}));


})( jQuery );
    

