// https://mmenujs.com/
import 'mmenu-js';
import 'mburger-webcomponent';

document.addEventListener(
	"DOMContentLoaded", () => {
		const homeLink = '<a href="/">Career Moves</a>';
		const ctaTabs = document.getElementById('menu-cta-menu').cloneNode(true);
		ctaTabs.querySelectorAll("li").forEach((listItem, i) => {
			listItem.classList.add('cta-button');
		});
		const navTabs = document.getElementById('genesis-nav-primary');
		if (navTabs != null){
			let navTabsClone = navTabs.cloneNode(true);
			navTabsClone.firstElementChild.insertAdjacentHTML('beforeend', ctaTabs.innerHTML);
			// console.log(navTabsClone);
			new Mmenu( navTabsClone, {
				theme: "dark",
				position: "left",
				navbar: {
					add: true,
					sticky: true,
					title: homeLink,
					titleLink: "anchor"
				},
				// navbars: [
				// 	{
				// 		position: "bottom",
				// 		content: ctaTabs.cloneNode(true)
				// 	}
				// ],
				offCanvas: {
					position: "left-front"
				}
			}, {
				// configuration
				offCanvas: {
					page: {
						selector: "#page-wrap"
					},
					clone: true
				},
				classNames: {
					selected: "current_page_item"
				},
				slidingSubmenus: false
			});
		}
	}
);